import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import {
  teacherStudentCheck,
  subscribeCheck,
  authCheck,
  subscribeManager,
  hideTariffIfAdmin,
  checkNotification,
  redirectFreePlanFromManagerPages,
} from '@/utils/routeCheck';
import { redirect403 } from '@/utils/constants';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/dashboard',
    children: [
      {
        path: '/programs',
        name: 'Programs',
        component: () => import('@/views/Teacher/Programs.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/programs/:programId',
        name: 'Program',
        component: () => import('@/views/Teacher/Program.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/courses',
        name: 'Courses',
        component: () => import('@/views/Teacher/Courses.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/:programId/:courseId/content',
        name: 'TeacherCourseContent',
        component: () => import('@/views/Teacher/CourseContentTeacher.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/course/:programId/:courseId',
        name: 'Course',
        component: () => import('@/views/Teacher/Course.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/program-user/:programId',
        name: 'ProgramUser',
        component: () => import('@/views/User/ProgramUser.vue'),
      },
      {
        path: '/course-user/:programId/:courseId',
        name: 'CourseUser',
        component: () => import('@/views/User/CourseUser.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/User/Dashboard.vue'),
      },
      {
        path: '/viewer/:programId/:courseId',
        name: 'Viewer',
        component: () => import('@/views/User/Viewer.vue'),
      },
      {
        path: redirect403,
        name: 'No Access',
        component: () => import('@/views/BanPage.vue'),
      },
      {
        path: '/manage-tariffs',
        name: 'ManageTariffs',
        component: () => import('@/views/Teacher/ManageTariffs.vue'),
      },
      {
        path: '/tariffs-detailed',
        name: 'TariffsDetailed',
        component: () => import('@/views/Teacher/TariffsDetailed.vue'),
      },
      {
        path: '/manage-students',
        name: 'ManageStudents',
        component: () => import('@/views/Teacher/ManageStudents.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/manage-homeworks-table',
        name: 'ManageHomeworks',
        component: () => import('../views/Teacher/TableHomeworksPage.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/check-homeworks',
        name: 'CheckHomeworksPage',
        component: () => import('../views/Teacher/CheckHomeworksPage.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/certificate-constructor/:programId/:courseId',
        name: 'CertificateConstructor',
        component: () => import('../views/Teacher/CertificateConstructor.vue'),
        meta: {
          role: 'teacher',
        },
      },
      {
        path: '/post-paid',
        name: 'PostPaidPage',
        component: () => import('../views/PostPaidPage.vue'),
        meta: {
          role: 'teacher',
        },
      },
    ],
  },
  {
    path: '/register',
    name: 'Registration',
    component: () => import('../views/Auth/Registration.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/auth',
    name: 'Authorization',
    component: () => import('../views/Auth/Authorization.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/Auth/ForgotPassword.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/Auth/ResetPassword.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: () => import('../views/Auth/ConfirmEmail.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/subscribe/:id',
    name: 'Subscribe',
    component: () => import('../views/SubscribeToProgram.vue'),
  },
  {
    path: '/subscribe-manager/:id',
    name: 'SubscribeManager',
    component: () => import('../views/SubscribeManager.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  teacherStudentCheck(to, from, next);
  await subscribeCheck(to, next);
  await subscribeManager(to, next);
  await authCheck(to, next);
  await hideTariffIfAdmin(to, next);
  await checkNotification(to, next);
  if (process.env.NODE_ENV !== 'development') {
    await redirectFreePlanFromManagerPages(to, next);
  }
});

export default router;
