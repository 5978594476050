<template>
  <div class="auth-layout">
    <div class="auth-layout__top">
      <router-link to="/auth">
        <div class="main-navbar__logo" />
      </router-link>
    </div>

    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>

export default {
  name: 'AuthLayout',
};
</script>
