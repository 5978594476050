import StudentService from '@/services/student.service';
import {
  deepFindActivity,
  deepFindActivityParent,
  deepFindChildrenByParentActivityId,
} from '@/utils/helpers';
import { materialType } from '@/utils/constants';
import router from '@/router';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  namespaced: true,

  state: {
    courseId: null,
    content: null,
    groupIdOfLevelOne: null,
    groupIdOfLevelTwo: null,
    selectedActivityId: null,

    questions: [],
    quizId: null,
  },

  actions: {
    selectActivityId({ commit }, item = null) {
      commit('SELECT_ACTIVITY_ID', item);
    },

    fetchCourseContent(
      { commit },
      { programId, courseId } = {
        programId: router.currentRoute.params.programId,
        courseId: router.currentRoute.params.courseId,
      },
    ) {
      return StudentService.getActivitiesOfCourse({ programId, courseId })
        .then(({ data }) => {
          commit('SET_CONTENT', { content: data, courseId });
          return data;
        })
        .catch(console.log);
    },
    setGroupIdOfLevelOne({ commit }, id) {
      return commit('SET_GROUP_ID_OF_LEVEL_ONE', id);
    },
    setGroupIdOfLevelTwo({ commit }, id) {
      return commit('SET_GROUP_ID_OF_LEVEL_TWO', id);
    },

    setActivityAsStarted({ dispatch }, id) {
      return StudentService.setActivityStarted(id)
        .then(() => dispatch('fetchCourseContent'))
        .catch(console.log);
    },
    setActivityAsCompleted({ dispatch }, id) {
      return StudentService.setActivityCompleted(id)
        .then(() => dispatch('fetchCourseContent'))
        .catch(console.log);
    },
    setSyncActivityAsStarted({ dispatch }) {
      dispatch('fetchCourseContent');
    },
    setSyncActivityAsCompleted({ dispatch }) {
      dispatch('fetchCourseContent');
    },

    setQuizId({ commit }, id) {
      commit('SET_QUIZ_ID', id);
    },

    sendAnswers({ commit, rootGetters }, { question, selectedAnswers, parent_statistic_id }) {
      const allQuestions = rootGetters['courseContentStudent/questions'];
      // eslint-disable-next-line max-len
      const isLastQuestion = allQuestions[allQuestions.length - 1]._id === question._id;
      return StudentService.submitQuestionAnswers({
        id: question._id,
        answers: selectedAnswers,
        parent_statistic_id,
      }).then(({ data }) => {
        // eslint-disable-next-line no-shadow
        const cloneQuestion = JSON.parse(JSON.stringify(question));
        cloneQuestion.statistic.meta = data;
        cloneQuestion.statistic.status = ActivityStatuses.done;

        commit('UPDATE_QUESTION', cloneQuestion);
        return isLastQuestion;
      });
    },
    restartQuiz({ commit, dispatch }, { quizId, parentStatisticId }) {
      return StudentService.restartQuiz(quizId, parentStatisticId).then(() => {
        commit('RESTART_QUIZ', quizId);
        dispatch('fetchCourseContent');
      });
    },
  },

  mutations: {
    SET_CONTENT(state, { content, courseId }) {
      state.content = content;
      state.courseId = courseId;
    },
    SET_GROUP_ID_OF_LEVEL_ONE(state, id) {
      state.groupIdOfLevelOne = id;
    },
    SET_GROUP_ID_OF_LEVEL_TWO(state, id) {
      state.groupIdOfLevelTwo = id;
    },

    SELECT_ACTIVITY_ID(state, id) {
      state.selectedActivityId = id;
    },

    SET_QUIZ_ID(state, id) {
      state.quizId = id;
    },
    RESET_QUESTIONS(state) {
      state.questions = [];
    },
    UPDATE_QUESTION({ content }, question) {
      const activity = deepFindActivity(content, question._id);
      Object.keys(activity).forEach((key) => {
        activity[key] = question[key];
      });
    },
    UPDATE_QUIZ_STATISTIC({ content }, quiz) {
      const activity = deepFindActivity(content, quiz._id);
      activity.statistic = quiz.statistic;
    },
    RESTART_QUIZ({ content }, quizId) {
      const quiz = deepFindActivity(content, quizId);
      quiz.statistic.status = ActivityStatuses.doing;
      if (!quiz.statistic.meta?.used_attempts) {
        quiz.statistic.meta = { used_attempts: 1 };
      } else {
        quiz.statistic.meta.used_attempts += 1;
      }
      const questions = deepFindChildrenByParentActivityId(content, quizId);
      questions.forEach((q) => {
        // eslint-disable-next-line no-param-reassign
        q.statistic.meta = null;
        // eslint-disable-next-line no-param-reassign
        q.statistic.status = ActivityStatuses.notStarted;
      });
    },
  },

  getters: {
    courseId: ({ courseId }) => courseId,
    content: ({ content }) => content,

    contentLevel: ({ groupIdOfLevelOne, groupIdOfLevelTwo }) => {
      if (groupIdOfLevelTwo) {
        return 2;
      }
      if (groupIdOfLevelOne) {
        return 1;
      }
      return 0;
    },

    materialOfSelectedGroup:
      (_, { groupsOfCourse }) => (groupId) => {
        const foundGroup = groupsOfCourse?.find((el) => el.activity._id === groupId);
        return foundGroup?.children?.filter((el) => materialType.includes(el.activity.type)) || [];
      },
    groupIdOfLevelOne: ({ groupIdOfLevelOne }) => groupIdOfLevelOne,
    groupIdOfLevelTwo: ({ groupIdOfLevelTwo }) => groupIdOfLevelTwo,
    // eslint-disable-next-line max-len

    isFullNestedContent: ({ content }) => {
      if (content?.children) {
        const groupsLvl1 = content.children.filter((el) => el.activity.type === 'group');
        const groupsLvl2 = groupsLvl1.reduce((acc, curr) => {
          if (curr?.children) {
            curr.children.map((el) => {
              if (el.activity.type === 'group') {
                acc.push(el);
              }
              return el;
            });
          }
          return acc;
        }, []);
        return !!groupsLvl2.length;
      }
      return false;
    },
    groupsOfLevel1InFullNested: ({ content }, { isFullNestedContent }) => {
      if (isFullNestedContent) {
        return content.children.filter((el) => el.activity.type === 'group');
      }
      return [];
    },

    selectedActivity: ({ content, selectedActivityId }) => {
      const selectedSection = deepFindActivityParent(content, selectedActivityId);
      const act = selectedSection?.children.find((el) => el.activity._id === selectedActivityId);
      if (act && act.activity?.type === 'group' && selectedSection?.children) {
        const firstActivity = act?.children?.find((el) => materialType.includes(el.activity.type));
        return firstActivity?.activity || null;
      }
      if (!act && selectedSection?.children) {
        const foundAction = selectedSection?.children.reduce((total, curr) => {
          if (curr?.children) {
            const found = curr.children.find((item) => item.activity._id === selectedActivityId);
            if (found) {
              return found;
            }
          }
          return total;
        }, {});
        if (foundAction) {
          return foundAction.activity;
        }
      }
      return act?.activity || null;
    },

    questions: ({ content, quizId }) => deepFindChildrenByParentActivityId(content, quizId),
    isQuizCompleted: (_, { questions }) => {
      const completedQuestions = questions.filter((q) => q.statistic.status
      === ActivityStatuses.done);
      return completedQuestions.length === questions.length;
    },
  },
};
