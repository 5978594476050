import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueMq from 'vue-mq';
import './http';
import LeraVueLibrary from '@centergame/lera-vue-library';
import ClickOutsideDirective from '@/directives/ClickOutside';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n/index';

import '@/assets/scss/index.scss';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(LeraVueLibrary);
Vue.use(ClickOutsideDirective);

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: Infinity,
  },
  defaultBreakpoint: 'sm',
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
