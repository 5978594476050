export default {
  namespaced: true,

  state: {
    showForm: false,
  },

  mutations: {
    TOGGLE_SHOW_FORM(state) {
      state.showForm = !state.showForm;
    },
  },

  getters: {
    showForm: ({ showForm }) => showForm,
  },
};
