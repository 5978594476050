import AuthService from '@/services/auth.service';
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';
import store from '@/store';
import { redirect403, redirectPageForUnauthorized } from '@/utils/constants';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import i18n from '../i18n/index';

const authRoutesName = [
  'Registration',
  'Authorization',
  'ForgotPassword',
  'ResetPassword',
  'ConfirmEmail',
];

const routesNotToCheckInTeacherStudent = ['ManageTariffs', 'TariffsDetailed'];

export const teacherStudentCheck = (to, from, next) => {
  if (authRoutesName.includes(to.name)) {
    return next();
  }

  const isTeacher = store.getters['auth/isModeTeacher'];

  /*
   * name === null initial page after reload
   */
  if (routesNotToCheckInTeacherStudent.includes(to.name) && from.name === null) {
    const isTeacherLocalStore = JSON.parse(localStorage.getItem('isModeTeacher'));
    if (!isTeacherLocalStore && isTeacher) {
      store.commit('auth/CHANGE_MODE_TEACHER');
    }
    return next();
  }

  if (to.meta.role === 'teacher' && !isTeacher) {
    store.commit('auth/CHANGE_MODE_TEACHER');
  } else if (!to.meta?.role && isTeacher && !routesNotToCheckInTeacherStudent.includes(to.name)) {
    store.commit('auth/CHANGE_MODE_TEACHER');
  }
  next();
};

export const subscribeCheck = async (to, next) => {
  if (to.name === 'Subscribe') {
    if (await AuthService.isAuth()) {
      const { id } = to.params;
      const { token } = to.query;
      return (
        ActivityService.appendStudent({ id, token })
          .then(async () => {
            const isTeacher = store.getters['auth/isModeTeacher'];
            if (isTeacher) {
              store.commit('auth/CHANGE_MODE_TEACHER');
            }
            return next(`/program-user/${id}`);
          })
          // eslint-disable-next-line no-unused-vars
          .catch((_) => {
            const isTeacher = store.getters['auth/isModeTeacher'];
            if (isTeacher) {
              store.commit('auth/CHANGE_MODE_TEACHER');
            }
            const toast = {
              type: 'toast-warning',
              toast: {
                title: i18n.t('errorMessages.somethingWentWrong'),
                body: i18n.t('errorMessages.errorOnSubscribeStudent'),
              },
            };
            store.dispatch('toaster/setToaster', toast);
            next(redirect403);
          })
      );
    }
    localStorage.setItem('subscribe-link', to.fullPath);
    return next(redirectPageForUnauthorized);
  }
};

export const subscribeManager = async (to, next) => {
  if (to.name === 'SubscribeManager') {
    const { id } = to.params;
    const { token } = to.query;
    if (await AuthService.isAuth()) {
      return (
        ActivityService.appendManager({ programId: id, token })
          .then(async () => {
            const isTeacher = store.getters['auth/isModeTeacher'];
            if (!isTeacher) {
              store.commit('auth/CHANGE_MODE_TEACHER');
            }
            return next(`/programs/${id}`);
          })
          // eslint-disable-next-line no-unused-vars
          .catch((e) => {
            const isAuthorError = e.response?.data?.error === "activity author can't be activity manager";
            const toast = {
              type: 'toast-warning',
              toast: {
                title: i18n.t('errorMessages.somethingWentWrong'),
                body: isAuthorError
                  ? i18n.t('errorMessages.authorIsNotManager')
                  : i18n.t('errorMessages.errorAddAdmin'),
              },
            };
            store.dispatch('toaster/setToaster', toast);
            const isTeacher = store.getters['auth/isModeTeacher'];
            if (!isTeacher) {
              store.commit('auth/CHANGE_MODE_TEACHER');
            }
            return isAuthorError ? next(`/programs/${id}`) : next(redirect403);
          })
      );
    }
    localStorage.setItem('subscribe-manager', JSON.stringify({ id, token }));
    return next(redirectPageForUnauthorized);
  }
};

export const authCheck = async (to, next) => {
  if (!authRoutesName.includes(to.name)) {
    await AuthService.checkAuth(next);
  } else {
    next();
  }
};

export const hideTariffIfAdmin = async (to, next) => {
  if (['ManageTariffs', 'TariffsDetailed'].includes(to.name)) {
    const isAdminTariff = store.getters['auth/getUserTariff']?.id === 9;
    return isAdminTariff ? next('/programs') : next();
  }
  next();
};
export const checkNotification = async (to, next) => {
  if (authRoutesName.includes(to.name)) {
    return next();
  }

  const showToast = () => {
    const toast = {
      type: 'toast-warning',
      toast: {
        title: i18n.t('supportText.checkStudentsCount'),
        body: i18n.t('supportText.tariffExpiresSoonCheckStudents'),
        button: {
          action: () => router.push({ name: 'ManageTariffs' }, () => {}),
          text: i18n.t('header.manageStudents'),
        },
      },
    };

    store.dispatch('toaster/setToaster', toast);
  };

  if (store.getters['auth/isTariffEndsSoon']) {
    const { user } = store.state.auth;

    // skip if next tariff includes equal number of students
    if (
      user?.subscription_details?.membership?.students_limit
      <= user?.subscription_details?.prolongation?.students_limit
    ) {
      return next();
    }

    if (user.id) {
      const throttleDelayMs = 7200000; // 2hours
      const notifications = JSON.parse(localStorage.getItem('lera-notification')) || {};
      // eslint-disable-next-line max-len
      const prevTimeStamp = notifications.tariffEndsLimitUsers && notifications.tariffEndsLimitUsers[user.id];

      if ((prevTimeStamp && +prevTimeStamp + throttleDelayMs - Date.now() < 0) || !prevTimeStamp) {
        showToast();

        if (notifications && notifications.tariffEndsLimitUsers) {
          notifications.tariffEndsLimitUsers[user.id] = Date.now();
          localStorage.setItem('lera-notification', JSON.stringify(notifications));
        } else {
          notifications.tariffEndsLimitUsers = { [user.id]: Date.now() };
          localStorage.setItem('lera-notification', JSON.stringify(notifications));
        }
      }
    }
  }
  next();
};

export const redirectFreePlanFromManagerPages = async (to, next) => {
  if (authRoutesName.includes(to.name)) {
    next();
  }

  const tariffPages = ['ManageTariffs', 'TariffsDetailed'];
  const isFreePlan = store.getters['auth/getUserTariff']?.id === 0;

  if (tariffPages.includes(to.name) && isFreePlan) {
    next('/dashboard/');
  }

  return to.meta.role === 'teacher' && isFreePlan ? next('/dashboard/') : next();
};
