<template>
  <div id="app">
    <component
      :is="layout"
    >
      <router-view />
    </component>
    <SplashScreen />
    <LmsToaster />
    <CookiesNotification />
  </div>
</template>
<script>
import SplashScreen from '@/components/Loader/SplashScreen.vue';
import LmsToaster from '@/components/LmsToaster.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import CookiesNotification from '@/components/CookiesNotification.vue';

export default {
  components: { LmsToaster, SplashScreen, CookiesNotification },
  computed: {
    layout() {
      return this.$route.meta?.layout === 'auth' ? AuthLayout : MainLayout;
    },
  },
  beforeCreate() {
    this.$store.dispatch('config/fetchConfiguration');
  },
  created() {
    window.addEventListener('resize', this.heightMobileFix);
  },
  mounted() {
    this.heightMobileFix();
  },
  methods: {
    heightMobileFix() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.heightMobileFix);
  },
};
</script>
