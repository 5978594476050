export default {
  namespaced: true,

  state: {
    isModalTypeCreated: false,
  },

  actions: {
    setModalTypeIsCreated({ commit }, bool) {
      commit('SET_MODAL_TYPE_IS_CREATED', bool);
    },
  },

  mutations: {
    SET_MODAL_TYPE_IS_CREATED(state, bool) {
      state.isModalTypeCreated = bool;
    },
  },

  getters: {
    getIsModalTypeCreated: ({ isModalTypeCreated }) => isModalTypeCreated,
  },
};
