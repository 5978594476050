<template>
  <div class="hesk" :class="{ opened: showForm, 'viewer-hesk': isRouteViewer }">
    <transition name="component-fade" mode="out-in">
      <div v-if="!showForm" key="button" class="hesk-button" @click="toggleShowForm">
        <AskIcon />
      </div>

      <div v-else class="hesk__form lms-card">
        <div class="hesk__title">
          <span>{{ $t('header.writeUs') }}</span>
          <SmallCloseButton @click="closeForm" />
        </div>

        <LeraSelector
          :selected="form.category"
          :options="options"
          :key="showForm"
          id="1"
          @change="selectCategory"
        />

        <b-form-textarea
          id="textarea-default"
          v-model="form.message"
          :placeholder="$t('editorjs.message')"
        ></b-form-textarea>

        <b-button
          type="submit"
          variant="primary"
          :disabled="disabled"
          class="w-100 f-center"
          @click="submit"
        >{{ $t('buttonLabels.send') }}</b-button
        >
      </div>
    </transition>
  </div>
</template>

<script>
import HeskService from '@/services/hesk.service';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AskIcon from '@/components/Icons/AskIcon.vue';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import LeraSelector from '@/components/LeraSelector.vue';

export default {
  name: 'Hesk',
  components: { LeraSelector, SmallCloseButton, AskIcon },
  data: () => ({
    form: { category: { value: null, text: '' }, message: '' },
    loading: false,
  }),
  computed: {
    ...mapGetters({
      email: 'auth/getEmail',
      fullName: 'auth/getFullName',
      showForm: 'hesk/showForm',
    }),
    options() {
      return [
        { value: 1, text: this.$t('supportText.technicalIssues') },
        { value: 2, text: this.$t('supportText.feedbackOfPlatform') },
        { value: 3, text: this.$t('supportText.questionAboutCrm') },
        { value: 4, text: this.$t('supportText.questionsAboutTariffs') },
        { value: 5, text: this.$t('supportText.other') },
      ];
    },
    disabled() {
      return !(this.form.category && this.form.message) || this.loading;
    },
    isRouteViewer() {
      return this.$route.name === 'Viewer';
    },
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    ...mapMutations({
      toggleShowForm: 'hesk/TOGGLE_SHOW_FORM',
    }),
    selectCategory(item) {
      this.form.category = item;
    },
    async submit() {
      this.loading = true;
      await HeskService.submitForm({
        message: this.form.message,
        category: this.form.category.value,
        name: this.fullName,
        email: this.email,
        subject: 'no subject',
      })
        .then(() => {
          this.closeForm();
          this.successfullyToast();
        })
        .catch(this.errorToast);
      this.loading = false;
    },
    closeForm() {
      this.toggleShowForm();
      this.form = { category: { value: null, text: '' }, message: '' };
    },
    successfullyToast() {
      const toast = {
        title: this.$t('header.messageSendSuccessfully'),
        body: this.$t('supportText.weWillLookOverYourMessage'),
      };
      this.setToaster({
        type: 'toast-success',
        toast,
      });
    },
    errorToast() {
      const toast = {
        title: this.$t('errorMessages.somethingWentWrong'),
        body: this.$t('errorMessages.tryAgain'),
      };
      this.setToaster({
        type: 'toast-danger',
        toast,
      });
    },
  },
};
</script>
