<template>
  <div class="main-layout" id="mainLayout">
    <router-view />
    <Footer v-if="isFooterShown" />
    <Hesk />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Footer from '@/components/Footer.vue';
import Hesk from '@/components/Hesk.vue';

export default {
  name: 'MainLayout',
  components: { Hesk, Footer },
  data: () => ({
    componentStyle: '',
    step2Inited: false,
  }),
  computed: {
    ...mapGetters({
      isTeacher: 'auth/isModeTeacher',
    }),
    isFooterShown() {
      const footerPages = [
        'Dashboard',
        'CourseUser',
        'ManageTariffs',
        'TariffsDetailed',
        'PostPaidPage',
      ];
      return this.isTeacher || footerPages.includes(this.$route.name);
    },
  },
};
</script>
