<template>
  <b-navbar id="mainNavbar" :toggleable="false" type="dark" :sticky="true" class="main-navbar">
    <b-navbar-brand class="main-navbar__logo" @click="logoIconRouteHandler"></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" class="main-navbar__links-container" is-nav>
      <b-navbar-nav>
        <b-nav-text
          v-for="link in menuLinks"
          :key="link.text"
          class="links-container__link"
          :class="{ 'links-container__link_active': $route.path === link.link }"
          @click="navigate(link.link)"
        >
          {{ link.text }}
        </b-nav-text>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <router-link
          v-if="!isAdminTariff && developmentMode"
          to="/manage-tariffs"
          class="nav-link"
          id="tariffs-link"
          >{{ $t('links.subscribe') }}</router-link
        >
        <b-dropdown
          class="main-navbar__dropdown dropdown"
          size="sm"
          right
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          style="text-transform: none"
          ref="dropdown"
        >
          <template #button-content>
            <b-nav-text class="main-navbar__user-mode">
              {{ isTeacher ? $t('label.manager') : $t('label.student') }}
            </b-nav-text>
            <b-nav-text class="main-navbar__user-first-name">{{ firstName }}</b-nav-text>
            <SmallUserButton />
          </template>

          <b-dropdown-header id="dropdown-header-label">
            <div class="dropdown__header">
              <div class="dropdown__username">{{ fullName }}</div>
              <div class="dropdown__email">{{ email }}</div>
            </div>
            <div class="dropdown__close-button">
              <SmallCloseButton @click="closeMobileDropDown" />
            </div>
          </b-dropdown-header>
          <div class="dropdown__wrapper">
            <template v-if="!$device.isDesktop">
              <b-dropdown-item
                v-for="menuItem in menuLinks"
                href="#"
                @click.prevent="navigate(menuItem.link)"
                :key="menuItem.link"
                :class="{ active: menuItem.link.includes($route.path) }"
              >
                <div class="user-mode">{{ menuItem.text }}</div>
              </b-dropdown-item>
            </template>

            <b-dropdown-item
              v-if="developmentMode || !isFreeTariff"
              href="#"
              @click.prevent="changeMode"
            >
              <div class="user-mode">
                {{ isTeacher ? $t('links.listenerMode') : $t('links.managerMode') }}
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isTeacher"
              href="#"
              @click.prevent="navigate('/manage-students')"
            >
              <div class="logout">{{ $t('header.manageStudents') }}</div>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isTeacher"
              href="#"
              @click.prevent="navigate('/manage-homeworks-table')"
            >
              <div class="logout">{{ $t('header.checkingHomeworks') }}</div>
            </b-dropdown-item>
            <b-dropdown-item v-if="$route.name === 'Viewer'" href="#" @click.prevent="showHesk">
              <div class="logout">{{ $t('header.writeUs') }}</div>
            </b-dropdown-item>
            <b-dropdown-item href="#" @click.prevent="logout">
              <div class="logout">{{ $t('links.logout') }}</div>
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AuthService from '@/services/auth.service';
import SmallUserButton from '@/components/Buttons/SmallUserButton.vue';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import { redirectPageForUnauthorized } from '@/utils/constants';
import deviceMixin from '@/mixins/device.mixin';

export default {
  name: 'MainNavbar',
  components: {
    SmallUserButton,
    SmallCloseButton,
  },
  mixins: [deviceMixin],
  computed: {
    ...mapGetters({
      isTeacher: 'auth/isModeTeacher',
      isAuth: 'auth/isAuthenticated',
      firstName: 'auth/getFirstName',
      email: 'auth/getEmail',
      fullName: 'auth/getFullName',
      userTariff: 'auth/getUserTariff',
    }),
    isAdminTariff() {
      return this.userTariff?.id === 9;
    },
    isFreeTariff() {
      return this.userTariff?.id === 0;
    },
    menuLinks() {
      return this.isTeacher
        ? [
          {
            text: this.$t('links.courses'),
            link: '/courses',
          },
          {
            text: this.$t('links.programs'),
            link: '/programs',
          },
        ]
        : [
          {
            text: this.$t('links.studyRoom'),
            link: '/dashboard',
          },
        ];
    },
    developmentMode() {
      return process.env.NODE_ENV === 'development';
    },
  },
  methods: {
    ...mapMutations({
      toggleShowForm: 'hesk/TOGGLE_SHOW_FORM',
    }),
    navigate(url) {
      if (this.$route.path !== url) {
        this.$router.push(url).catch(() => {});
      }
    },
    logoIconRouteHandler() {
      if (this.isTeacher) {
        if (this.$route.path !== '/programs') {
          return this.$router.push('/programs');
        }
        return;
      }
      if (this.$route.path !== '/dashboard') {
        this.$router.push('/dashboard');
      }
    },
    async logout() {
      AuthService.logout();
      await this.$router.replace(redirectPageForUnauthorized);
    },
    changeMode() {
      this.$store.commit('loader/ACTIVATE_LOADER', true);
      setTimeout(() => {
        this.$store.commit('loader/ACTIVATE_LOADER', false);
        this.$store.commit('auth/CHANGE_MODE_TEACHER');
        if (this.isTeacher) {
          return this.$router.push('/programs');
        }
        this.$router.push('/dashboard');
      }, 300);
    },
    closeMobileDropDown() {
      this.$refs.dropdown.hide(true);
    },
    showHesk() {
      this.toggleShowForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-navbar__dropdown ::v-deep .btn:focus {
  box-shadow: none;
}

.navbar-nav ::v-deep .main-navbar__user-first-name {
  color: #f2f2f2;
}

.navbar-nav ::v-deep .main-navbar__user-mode {
  color: #999999;
}

.main-navbar__dropdown ::v-deep .dropdown-item:hover {
  background: #273344;
  color: #f2f2f2;
}

.main-navbar__dropdown ::v-deep .dropdown-item {
  padding: 0.5rem 1.25rem;
}

.main-navbar__dropdown ::v-deep li:not(:last-child) .dropdown-item {
  margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
@media (max-width: 480px) {
  #mainNavbar ::v-deep .dropdown-menu.dropdown-menu-right {
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100vw);
    transition: transform 250ms ease-out;
    position: fixed;
  }
  #mainNavbar ::v-deep .dropdown-menu.dropdown-menu-right.show {
    transform: translateX(0);
  }
}
</style>
