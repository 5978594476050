import axios from '@/http/index';
import CheckAccess from '@/utils/checkAccessDecorator';
import { accessToken } from '@/utils/constants';

const getAll = '?page=1&per_page=999';

class StudentService {
  @CheckAccess
  getPrograms = () => axios.get(`student/activity/applied-programs${getAll}`);

  @CheckAccess
  getProgramCourses = (programId) => axios.get(`student/activity/program-courses/${programId}${getAll}`);

  @CheckAccess
  getCourse = ({ id, programId }) => axios.get(`student/activity/course-info/${id}/${programId}`);

  @CheckAccess
  getCourseMaterials = ({ id, programId }) => axios.get(`student/activity/course-content/${id}/${programId}${getAll}`);

  @CheckAccess
  getStudentActivitiesWithParent = ({ activityId, parentId }) => axios.get(`student/activity/${parentId}/with_parent_id/${activityId}${getAll}`);

  @CheckAccess
  getActivityById = ({ activityId, programId }) => axios.get(`student/activity/${programId}/${activityId}`);

  @CheckAccess
  setActivityStarted = (id) => axios.post(`student/activity/${id}/set_started`);

  @CheckAccess
  setActivityCompleted = (id) => axios.post(`student/activity/${id}/set_completed`);

  @CheckAccess
  submitQuestionAnswers = ({ id, answers, parent_statistic_id }) => axios.post(`student/activity/${id}/submit_answers`, {
    parent_statistic_id,
    answers,
  });

  @CheckAccess
  startChat = ({ activity_id, activity_context }) => axios.post('student/activity/task/chat', {
    activity_id,
    activity_context,
  });

  @CheckAccess
  sendChatMessageByStudent = ({
    activity_id,
    activity_context,
    text,
    attachment_url,
    attachment_name,
  }) => axios.post(`student/activity/task/chat/${activity_id}/message `, {
    activity_context,
    text,
    attachment_url,
    attachment_name,
    message_type: 1,
  });

  @CheckAccess
  sendChatMessageByTeacher = ({
    task_chat_id, text, attachment_url, attachment_name, message_type, extension,
  }) => axios.post(`teacher/task/chat/${task_chat_id}/message `, {
    text,
    attachment_url,
    attachment_name,
    message_type,
    extension,
  });

  @CheckAccess
  getChatAsStudent = ({ activity_id, activity_context }) => axios.post(`student/activity/task/chat/${activity_id} `, {
    activity_context,
  });

  @CheckAccess
  getChatAsTeacher = ({ task_chat_id }) => axios.get(`teacher/task/chat/${task_chat_id} `);

  @CheckAccess
  getChatsList = () => axios.get('teacher/task/chat/')

  @CheckAccess
  getActivitiesOfCourse = ({ programId, courseId }) => {
    const url = process.env.VUE_APP_BACKEND_HOST_V2;
    return axios.get(`${url}/student/programs/${programId}/courses/${courseId}`);
  };

  @CheckAccess
  restartQuiz = (quizId, parentStatisticId) => axios.post(`student/activity/${quizId}/start_quiz`, { parent_statistic_id: parentStatisticId });

  @CheckAccess
  getCertificate = (activityId) => axios.get(`student/activity/${activityId}/certificate`);

  @CheckAccess
  submitSpentTime = (activityId, time) => axios.post(`student/activity/${activityId}/update_time_spent`, { seconds: time });

  @CheckAccess
  submitSpentTimeOnLeave = (activityId, time) => {
    const access_token = localStorage.getItem(accessToken);
    return fetch(
      `${process.env.VUE_APP_BACKEND_HOST}/student/activity/${activityId}/update_time_spent`, {
        method: 'POST',
        headers: new Headers({
          Authorization: access_token,
          'Content-Type': 'application/json;charset=utf-8',
        }),
        body: JSON.stringify({
          seconds: time,
        }),
      },
    );
  }
}

export default new StudentService();
